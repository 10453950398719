var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Layout',{attrs:{"type":"LayoutDefault"}},[_c('section',{attrs:{"id":"Home"}},[(_vm.isSetDataReady.getAdCarouselList)?_c('AdCarousel',{key:_vm.renderCarouselKey,attrs:{"list":_vm.adCarouselList}}):_c('div',{staticClass:"banner-block"},[_c('div',{staticClass:"banner-content"},[_c('DotLoading',{staticClass:"banner-loading"})],1)]),_c('div',{staticClass:"home-block back-color"},[_c('div',{staticClass:"home-content"},[_c('h1',{staticClass:"block-title main baseline"},[_vm._v("這是測試網站")]),_c('div',{staticClass:"block-title mt-4"},[_c('div',{staticClass:"orange"},[_c('p',[_vm._v("這是測試網站")])])])])]),_vm._l((_vm.allRecommendSegmentList),function(item){return _c('div',{key:item.name,staticClass:"home-block back-color recommend-course"},[_c('div',{staticClass:"home-content"},[_c('div',{staticClass:"right-side-arrows",class:[_vm.windowWidth < 768 ? 'arrow-hidden' : '']},[_c('div',{staticClass:"center-div"},[_c('h2',{staticClass:"block-title"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"block-title"},[_c('div',{staticClass:"descr m-t"},[_c('p',[_vm._v(" "+_vm._s(item.content)+" ")])])]),(_vm.isSetDataReady.getRecommendCourseList && item.courseList.length > 4)?_c('div',{staticClass:"right-div"},[_c('div',{staticClass:"arrow-btns-div"},[_c('button',{staticClass:"btn lms-btn icon-white-rounded arrow-left",on:{"click":function($event){$event.target.blur();
                                        item.renderedCourseList = _vm.filterRenderList(item.courseList, item.courseListPageIndex, 4, 'prev');}}}),_c('button',{staticClass:"btn lms-btn icon-white-rounded arrow-right",on:{"click":function($event){$event.target.blur();
                                        item.renderedCourseList = _vm.filterRenderList(item.courseList, item.courseListPageIndex, 4, 'next');}}})])]):_vm._e()]),(_vm.isSetDataReady.getRecommendCourseList)?_c('div',{staticClass:"row no-gutters horizontal-scroll-wrapper recommend-course-list",class:[_vm.windowWidth >= 768 && (item.courseList.length && item.courseList.length <= 3) ? 'is-center-content' : '']},[_vm._l((item.renderedCourseList),function(course){return _c('div',{key:course.id,staticClass:"col-12 col-md-3 hs-item course-item"},[_c('router-link',{attrs:{"to":`/course/${course.alias}`}},[_c('CourseCard',{attrs:{"info":{
                                    cover: course.cover,
                                    name: course.name,
                                    subName: course.subName,
                                    purchaseType: course.purchaseType,
                                    price: course.price,
                                    isPreOrder: course.isPreOrder,
                                    suitable: course.suitable,
                                    difficultyLevel: course.difficultyLevel
                                }}})],1)],1)}),(!item.courseList.length)?_c('span',{staticClass:"col-12 no-item-tip"},[_vm._v("尚無課程")]):_vm._e()],2):_c('DotLoading')],1)])}),_c('div',{staticClass:"home-block public-article"},[_c('div',{staticClass:"home-content special-courses"},[_c('div',{staticClass:"right-side-arrows",class:[_vm.windowWidth < 768 ? 'arrow-hidden' : '']},[_c('div',{staticClass:"center-div"},[_c('h2',{staticClass:"block-title"},[_vm._v("國中會考準備課程 • 搶先體驗")])]),_c('div',{staticClass:"block-title"},[_c('div',{staticClass:"descr m-t"},[_c('p',[_vm._v(" 以下皆開放試閱課程內容，提供對課程有興趣的您先行體驗，若想了解更多課程內容應用方式，也歡迎隨時與我們聯絡！ ")])])]),(_vm.isSetDataReady.getPublicArticleList && _vm.allPublicArticleList.length > 8)?_c('div',{staticClass:"right-div"},[_c('div',{staticClass:"arrow-btns-div"},[_c('button',{staticClass:"btn lms-btn icon-white-rounded arrow-left",on:{"click":function($event){$event.target.blur();
                                        _vm.renderedArticleList = _vm.filterRenderList(_vm.allPublicArticleList, _vm.articleListPageIndex, 8, 'prev');}}}),_c('button',{staticClass:"btn lms-btn icon-white-rounded arrow-right",on:{"click":function($event){$event.target.blur();
                                        _vm.renderedArticleList = _vm.filterRenderList(_vm.allPublicArticleList, _vm.articleListPageIndex, 8, 'next');}}})])]):_vm._e()]),(_vm.isSetDataReady.getPublicArticleList)?_c('div',{staticClass:"row no-gutters horizontal-scroll-wrapper public-article-list"},[_vm._l((_vm.renderedArticleList),function(article){return _c('div',{key:article.id,staticClass:"col-12 col-md-3 hs-item article-item"},[_c('router-link',{attrs:{"to":`/article/${article.id}`}},[_c('ArticleCard',{attrs:{"info":{
                                    name: article.name,
                                    cover: article.cover,
                                    difficultyLevel: article.difficultyLevel,
                                    category: article.category,
                                    practiceTypes: article.practiceTypes,
                                    courseName: article.courseName
                                }}})],1)],1)}),(!_vm.allPublicArticleList.length)?_c('span',{staticClass:"col-12 no-item-tip"},[_vm._v("尚無文章")]):_vm._e()],2):_c('DotLoading')],1)]),_c('div',{staticClass:"home-block back-color"},[_c('div',{staticClass:"home-content"},[_c('h2',{staticClass:"block-title baseline orange"},[_c('p',{staticClass:"p-2"},[_c('span',{staticClass:"size-48"},[_vm._v("學習")]),_c('span',{staticClass:"size-35"},[_vm._v("的美好之處，是沒有人可以把它從你身上拿走。")])])]),_c('div',{staticClass:"block-title baseline orange"},[_c('p',{staticClass:"descr"},[_vm._v("音樂家 B.B. King")])])])]),_c('AnnouncementDialogue')],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }